import React from "react";
import "./GameChanger.css";

function GameChanger() {
  return (
    <div className="gameChanger">
      <div className="gameChanger__iFrame">
        <iframe
          title="gamechanger"
          src="//gc.com/scoreboard-5f88a82dba69b7fcaebbeca7?g=5&p=58ac7803378dca001a989c9c"
          width="728"
          height="90"
          scrolling="no"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
}

export default GameChanger;
