import React, { useEffect, useState } from "react";
import Logo from "../../Images/razorbackLogoFace.png";
import Brighton from "../../Images/BrightonAction.jpg";
import { useStateValue } from "../../assets/stateProvider";
import "./PitcherStory.css";
import { pitcherOfTheWeek } from "../../assets/functions";

function PitcherStory() {
  const [{ myPlayerData }, dispatch] = useStateValue(null);

  const [era, setEra] = useState("");

  useEffect(() => {
    setEra(pitcherOfTheWeek(myPlayerData)[0]);
  }, []);

  return (
    <div className="pitcherStory">
      <h2>Pitching Champ</h2>
      <h3>#{era.number}, {era.firstName}</h3>
      <figure className="pitcherStory__card">
        <img
          src={Logo}
          alt="razorback hog"
          className="pitcherStory__cardLogo"
        />
        <img
          src={era.dImage ? era.dImage : era.oImage ? era.oImage : Brighton}
          alt="player"
          className="pitcherStory__cardPlayer"
        />

        <figcaption>
          <div className="pitcherStory__figcaptionCol">
            <p style={{ color: "black", padding: 0, margin: 0 }}>
              #{era ? era.number : null}
            </p>
            <p style={{ color: "black", padding: 0, margin: 0 }}>
              {era ? era.firstName : null}
            </p>
          </div>

          <div className="pitcherStory__figcaptionCol">
            {" "}
            <p style={{ color: "black", padding: 0, margin: 0 }}>
              Player Score
            </p>
            <p style={{ color: "black", padding: 0, margin: 0 }}>
              {era ? era.evalScore : null}
            </p>
            {/* <p style={{ color: "black", padding: 0, margin: 0 }}>
              ERA: {era[0] ? parseFloat(era[0].era).toFixed(2) : null}
            </p> */}
          </div>
        </figcaption>
      </figure>
    </div>
  );
}

export default PitcherStory;
