

let schedule = [
  {
    id: Math.random(),
    opponent: "SC Storm",
    date:  new Date(2021, 2, 11).toDateString(),    
    time: "3:00pm",
    arrival: "2:00pm",
    address: "1358 N Canyon View Dr",
    city: "Santa Clara",
    uniform: "white",
    team: "home",
  },

  {
    id: Math.random(),
    opponent: "Davis Aces",
    date:  new Date(2021, 2, 11).toDateString(),    
    time: "7:00pm",
    arrival: "6:00pm",
    address: "1358 N Canyon View Dr",
    city: "Santa Clara",
    uniform: "grey",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Uintah",
    date:  new Date(2021, 2, 25).toDateString(),    
    time: "9:00am",
    arrival: "8:00pm",
    address: "501 Hillside Dr",
    city: "Mesquite",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Nevada Knights",
    date:  new Date(2021, 2, 25).toDateString(), 
    time: "1:30pm",
    arrival: "12:30pm",
    address: "501 Hillside Dr",
    city: "Mesquite",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Bear River Bears",
    date:  new Date(2021, 2, 26).toDateString(), 
    time: "5:00pm",
    arrival: "4:00pm",
    address: "1358 N Canyon View Dr",
    city: "Santa Clara",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "Minico Storm",
    date:  new Date(2021, 2, 26).toDateString(), 
    time: "7:00pm",
    arrival: "6:00pm",
    address: "1358 N Canyon View Dr",
    city: "Santa Clara",
    uniform: "grey",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "UT Jaguars",
    date:  new Date(2021, 3, 5).toDateString(), 
    time: "7:45pm",
    arrival: "6:45pm",
    address: "CRH #5",
    city: "Riverton",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "UT Swarm",
    date:  new Date(2021, 3, 12).toDateString(), 
    time: "7:45pm",
    arrival: "6:45pm",
    address: "CRH #5",
    city: "Riverton",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Hitmen",
    date:  new Date(2021, 3, 14).toDateString(), 
    time: "5:30pm",
    arrival: "4:30pm",
    address: "Oquirrh Park #2",
    city: "Kearns",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "WC Eagles",
    date:  new Date(2021, 3, 15).toDateString(), 
    time: "7:45pm",
    arrival: "6:45pm",
    address: "CRH #5",
    city: "Riverton",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Storm",
    date:  new Date(2021, 3, 17).toDateString(), 
    time: "3:00pm",
    arrival: "2:00pm",
    address: "600 E Canal Rd",
    city: "Salem",
    uniform: "grey",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "SF Stars",
    date:  new Date(2021, 3, 17).toDateString(), 
    time: "5:00pm",
    arrival: "4:00pm",
    address: "600 E Canal Rd",
    city: "Salem",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "Olympus Black",
    date:  new Date(2021, 3, 19).toDateString(), 
    time: "7:30pm",
    arrival: "6:30pm",
    address: "Oquirrh Park #1",
    city: "Kearns",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "Olympus Titans",
    date:  new Date(2021, 3, 22).toDateString(), 
    time: "7:30pm",
    arrival: "6:30pm",
    address: "CRH #5",
    city: "Riverton",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Olympus Titans",
    date:  new Date(2021, 3, 24).toDateString(), 
    time: "11:00am",
    arrival: "10:00am",
    address: "Parkway S",
    city: "Alpine",
    uniform: "grey",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Sentinels",
    date:  new Date(2021, 3, 24).toDateString(), 
    time: "1:00pm",
    arrival: "12:00pm",
    address: "Parkway S",
    city: "Alpine",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "Olympus Titans",
    date:  new Date(2021, 3, 26).toDateString(), 
    time: "7:30pm",
    arrival: "6:30pm",
    address: "Quirrh Park #1",
    city: "Kearns",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "Herriman Hornets",
    date:  new Date(2021, 3, 29).toDateString(), 
    time: "7:30pm",
    arrival: "6:30pm",
    address: "Herriman NE",
    city: "Herriman",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "PC Miners",
    date:  new Date(2021, 4, 1).toDateString(), 
    time: "3:00pm",
    arrival: "2:00pm",
    address: "391 S Saratoga Rd",
    city: "Saratoga",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Scrappers",
    date:  new Date(2021, 4, 1).toDateString(), 
    time: "5:00pm",
    arrival: "4:00pm",
    address: "391 S Saratoga Rd",
    city: "Saratoga",
    uniform: "white",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "Olympus Black",
    date:  new Date(2021, 4, 3).toDateString(), 
    time: "7:30pm",
    arrival: "6:30pm",
    address: "CRH #5",
    city: "Riverton",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "TC Hawks",
    date:  new Date(2021, 4, 8).toDateString(), 
    time: "1:00pm",
    arrival: "12:00pm",
    address: "391 S Saratoga Rd",
    city: "Saratoga",
    uniform: "grey",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "Renegades",
    date:  new Date(2021, 4, 8).toDateString(), 
    time: "3:00pm",
    arrival: "3:00pm",
    address: "391 S Saratoga Rd",
    city: "Saratoga",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "Herriman Hornets",
    date:  new Date(2021, 4, 10).toDateString(), 
    time: "7:30pm",
    arrival: "6:30pm",
    address: "CRH #5",
    city: "Riverton",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "WC Eagles",
    date:  new Date(2021, 4, 11).toDateString(), 
    time: "5:30pm",
    arrival: "4:30pm",
    address: "Bywater",
    city: "Cottonwood Heights",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "Hitmen",
    date:  new Date(2021, 4, 17).toDateString(), 
    time: "7:30pm",
    arrival: "6:30pm",
    address: "CRH #5",
    city: "Riverton",
    uniform: "white",
    team: "home",
  },
  {
    id: Math.random(),
    opponent: "UT Swarm",
    date:  new Date(2021, 4, 19).toDateString(), 
    time: "5:30pm",
    arrival: "4:30pm",
    address: "Oquirrh Park #2",
    city: "Kearns",
    uniform: "grey",
    team: "away",
  },
  {
    id: Math.random(),
    opponent: "UT Jaguars",
    date:  new Date(2021, 4, 20).toDateString(), 
    time: "7:30pm",
    arrival: "6:30pm",
    address: "CRH #5",
    city: "Riverton",
    uniform: "white",
    team: "home",
  },
];

export default schedule;
