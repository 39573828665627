import {
  brighton,
  tyson,
  blakec,
  ryker,
  carson,
  kamden,
  razorbackface,
  bowen,
  austin,
  spencer,
  blakey,
  kalen,
  blakeCBat,
  bowenCatch,
  brighton1st,
  brightonBat,
  rykerPitch,
  tysonBat,
  tysonPitch,

} from "./imagesData";



let localTeamData = [
  {
    id: "8",
    position: "1B, SS, P, OF",
    image: brighton,
    dImage: brighton1st,
    oImage: brightonBat,

  },
  {
    id: "23",
    position: "1B, 2B, OF",
    image: tyson,
    dImage: tysonPitch,
    oImage: tysonBat,
  },
  {
    id: "5",
    position: "3B, P, OF",
    image: blakec,
    dImage: null,
    oImage: blakeCBat,
  },
  {
    id: "34",
    position: "3B, SS, P",
    image: ryker,
    dImage: rykerPitch,
    oImage: null,
  },
  {
    id: "10",
    position: "SS, OF, P",
    image: carson,
    dImage: null,
    oImage: null,
  },
  {
    id: "26",
    position: "1B, OF, P",
    image: kamden,
    dImage: null,
    oImage: null,
  },
  {
    id: "27",
    position: "3B, SS, P, OF",
    image: razorbackface,
    dImage: null,
    oImage: null,
  },
  {
    id: "47",
    position: "C, 2B, OF",
    image: bowen,
    dImage: bowenCatch,
    oImage: null,
  },
  {
    id: "45",
    position: "3B, P",
    image: austin,
    dImage: null,
    oImage: null,
  },
  {
    id: "80",
    position: "OF",
    image: spencer,
    dImage: null,
    oImage: null,
  },
  {
    id: "12",
    position: "2B, SS, OF, C",
    image: blakey,
    dImage: null,
    oImage: null,
  },
  {
    id: "42",
    position: "2B, 3B, OF",
    image: kalen,
    dImage: null,
    oImage: null,
  },
];

export default localTeamData;
