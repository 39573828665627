let gameChangerData = [
  {
    "FIELD1": "Number",
    "FIELD2": "Last",
    "FIELD3": "First",
    "Offensive Stats": "GP",
    "FIELD5": "PA",
    "FIELD6": "AB",
    "FIELD7": "H",
    "FIELD8": "1B",
    "FIELD9": "2B",
    "FIELD10": "3B",
    "FIELD11": "HR",
    "FIELD12": "RBI",
    "FIELD13": "R",
    "FIELD14": "HBP",
    "FIELD15": "ROE",
    "FIELD16": "FC",
    "FIELD17": "CI",
    "FIELD18": "BB",
    "FIELD19": "SO",
    "FIELD20": "AVG",
    "FIELD21": "OBP",
    "FIELD22": "SLG",
    "FIELD23": "OPS",
    "FIELD24": "PA/BB",
    "FIELD25": "BB/K",
    "FIELD26": "C%",
    "FIELD27": "K-L",
    "FIELD28": "SB",
    "FIELD29": "CS",
    "FIELD30": "SB%",
    "FIELD31": "PIK",
    "FIELD32": "GIDP",
    "FIELD33": "GITP",
    "FIELD34": "XBH",
    "FIELD35": "TB",
    "FIELD36": "AB/HR",
    "FIELD37": "BA/RISP",
    "FIELD38": "PS",
    "FIELD39": "PS/PA",
    "FIELD40": "2S+3",
    "FIELD41": "2S+3%",
    "FIELD42": "6+",
    "FIELD43": "6+%",
    "FIELD44": "FLB%",
    "FIELD45": "GB%",
    "FIELD46": "SAC",
    "FIELD47": "SF",
    "FIELD48": "LOB",
    "FIELD49": "2OUTRBI",
    "FIELD50": "HHB",
    "FIELD51": "QAB",
    "FIELD52": "QAB%",
    "FIELD53": "BABIP",
    "Defensive Stats": "IP",
    "FIELD55": "GP",
    "FIELD56": "GS",
    "FIELD57": "W",
    "FIELD58": "L",
    "FIELD59": "SV",
    "FIELD60": "SVO",
    "FIELD61": "BS",
    "FIELD62": "SV%",
    "FIELD63": "H",
    "FIELD64": "R",
    "FIELD65": "ER",
    "FIELD66": "BB",
    "FIELD67": "SO",
    "FIELD68": "HBP",
    "FIELD69": "ERA",
    "FIELD70": "WHIP",
    "FIELD71": "BF",
    "FIELD72": "#P",
    "FIELD73": "TS",
    "FIELD74": "TB",
    "FIELD75": "P/IP",
    "FIELD76": "P/BF",
    "FIELD77": "<3",
    "FIELD78": "<3%",
    "FIELD79": "LOO",
    "FIELD80": "1ST2OUT",
    "FIELD81": "123INN",
    "FIELD82": "<13",
    "FIELD83": "FIP",
    "FIELD84": "S%",
    "FIELD85": "FPS",
    "FIELD86": "FPS%",
    "FIELD87": "FPSO%",
    "FIELD88": "FPSW%",
    "FIELD89": "FPSH%",
    "FIELD90": "BB/INN",
    "FIELD91": "0BBINN",
    "FIELD92": "BBS",
    "FIELD93": "LOBB",
    "FIELD94": "LOBBS",
    "FIELD95": "WP",
    "FIELD96": "ABA",
    "FIELD97": "SM",
    "FIELD98": "SM%",
    "FIELD99": "K/G",
    "FIELD100": "K/BF",
    "FIELD101": "K/BB",
    "FIELD102": "WEAK%",
    "FIELD103": "HHB%",
    "FIELD104": "GB%",
    "FIELD105": "FLB%",
    "FIELD106": "GO",
    "FIELD107": "FO",
    "FIELD108": "GO/FO",
    "FIELD109": "BAA",
    "FIELD110": "HR",
    "FIELD111": "BABIP",
    "FIELD112": "LOB",
    "FIELD113": "BK",
    "FIELD114": "PIK",
    "FIELD115": "SB",
    "FIELD116": "CS",
    "FIELD117": "SB%",
    "FIELD118": "FB",
    "FIELD119": "FBS",
    "FIELD120": "FBS%",
    "FIELD121": "CT",
    "FIELD122": "CTS",
    "FIELD123": "CTS%",
    "FIELD124": "CB",
    "FIELD125": "CBS",
    "FIELD126": "CBS%",
    "FIELD127": "SL",
    "FIELD128": "SLS",
    "FIELD129": "SLS%",
    "FIELD130": "CH",
    "FIELD131": "CHS",
    "FIELD132": "CHS%",
    "FIELD133": "OS",
    "FIELD134": "OSS",
    "FIELD135": "OSS%",
    "FIELD136": "TC",
    "FIELD137": "A",
    "FIELD138": "PO",
    "FIELD139": "E",
    "FIELD140": "DP",
    "FIELD141": "TP",
    "FIELD142": "FPCT",
    "FIELD143": "cINN",
    "FIELD144": "cPB",
    "FIELD145": "cSB",
    "FIELD146": "cCS",
    "FIELD147": "cCS%",
    "FIELD148": "cPIK",
    "FIELD149": "cCI"
  },
  {
    "FIELD1": "5",
    "FIELD2": "Coles",
    "FIELD3": "Blake",
    "Offensive Stats": "36",
    "FIELD5": "77",
    "FIELD6": "59",
    "FIELD7": "18",
    "FIELD8": "16",
    "FIELD9": "2",
    "FIELD10": "0",
    "FIELD11": "0",
    "FIELD12": "15",
    "FIELD13": "19",
    "FIELD14": "2",
    "FIELD15": "2",
    "FIELD16": "0",
    "FIELD17": "0",
    "FIELD18": "15",
    "FIELD19": "15",
    "FIELD20": ".305",
    "FIELD21": ".461",
    "FIELD22": ".339",
    "FIELD23": ".800",
    "FIELD24": "5.133",
    "FIELD25": "1.000",
    "FIELD26": ".746",
    "FIELD27": "8",
    "FIELD28": "11",
    "FIELD29": "0",
    "FIELD30": "1.000",
    "FIELD31": "0",
    "FIELD32": "2",
    "FIELD33": "0",
    "FIELD34": "2",
    "FIELD35": "20",
    "FIELD36": ".000",
    "FIELD37": ".263",
    "FIELD38": "268",
    "FIELD39": "3.481",
    "FIELD40": "4",
    "FIELD41": ".052",
    "FIELD42": "9",
    "FIELD43": ".117",
    "FIELD44": "31.11%",
    "FIELD45": "48.89%",
    "FIELD46": "1",
    "FIELD47": "0",
    "FIELD48": "53",
    "FIELD49": "4",
    "FIELD50": "10",
    "FIELD51": "30",
    "FIELD52": "38.96%",
    "FIELD53": ".409",
    "Defensive Stats": "13.1",
    "FIELD55": "10",
    "FIELD56": "2",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "38",
    "FIELD64": "39",
    "FIELD65": "36",
    "FIELD66": "21",
    "FIELD67": "11",
    "FIELD68": "6",
    "FIELD69": "18.900",
    "FIELD70": "4.425",
    "FIELD71": "100",
    "FIELD72": "352",
    "FIELD73": "177",
    "FIELD74": "175",
    "FIELD75": "26.400",
    "FIELD76": "3.520",
    "FIELD77": "48",
    "FIELD78": ".480",
    "FIELD79": "5",
    "FIELD80": "2",
    "FIELD81": "2",
    "FIELD82": "4",
    "FIELD83": "6.796",
    "FIELD84": ".503",
    "FIELD85": "51",
    "FIELD86": ".510",
    "FIELD87": ".373",
    "FIELD88": ".078",
    "FIELD89": ".529",
    "FIELD90": "1.575",
    "FIELD91": "5",
    "FIELD92": "14",
    "FIELD93": "5",
    "FIELD94": "5",
    "FIELD95": "7",
    "FIELD96": "73",
    "FIELD97": "23",
    "FIELD98": ".065",
    "FIELD99": "5.775",
    "FIELD100": ".110",
    "FIELD101": ".524",
    "FIELD102": ".532",
    "FIELD103": ".468",
    "FIELD104": ".361",
    "FIELD105": ".639",
    "FIELD106": "7",
    "FIELD107": "15",
    "FIELD108": ".467",
    "FIELD109": ".521",
    "FIELD110": "0",
    "FIELD111": ".613",
    "FIELD112": "16",
    "FIELD113": "0",
    "FIELD114": "3",
    "FIELD115": "15",
    "FIELD116": "0",
    "FIELD117": "1.000",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "51",
    "FIELD137": "21",
    "FIELD138": "25",
    "FIELD139": "5",
    "FIELD140": "7",
    "FIELD141": "0",
    "FIELD142": ".902",
    "FIELD143": "1.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "8",
    "FIELD2": "Young",
    "FIELD3": "8righton",
    "Offensive Stats": "36",
    "FIELD5": "107",
    "FIELD6": "85",
    "FIELD7": "46",
    "FIELD8": "29",
    "FIELD9": "15",
    "FIELD10": "2",
    "FIELD11": "0",
    "FIELD12": "35",
    "FIELD13": "32",
    "FIELD14": "8",
    "FIELD15": "1",
    "FIELD16": "1",
    "FIELD17": "0",
    "FIELD18": "14",
    "FIELD19": "9",
    "FIELD20": ".541",
    "FIELD21": ".636",
    "FIELD22": ".765",
    "FIELD23": "1.400",
    "FIELD24": "7.643",
    "FIELD25": "1.556",
    "FIELD26": ".894",
    "FIELD27": "2",
    "FIELD28": "20",
    "FIELD29": "2",
    "FIELD30": ".909",
    "FIELD31": "1",
    "FIELD32": "1",
    "FIELD33": "0",
    "FIELD34": "17",
    "FIELD35": "65",
    "FIELD36": ".000",
    "FIELD37": ".568",
    "FIELD38": "342",
    "FIELD39": "3.196",
    "FIELD40": "11",
    "FIELD41": ".103",
    "FIELD42": "12",
    "FIELD43": ".112",
    "FIELD44": "10.53%",
    "FIELD45": "42.11%",
    "FIELD46": "0",
    "FIELD47": "0",
    "FIELD48": "37",
    "FIELD49": "15",
    "FIELD50": "55",
    "FIELD51": "76",
    "FIELD52": "71.03%",
    "FIELD53": ".605",
    "Defensive Stats": "30.1",
    "FIELD55": "15",
    "FIELD56": "8",
    "FIELD57": "2",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "47",
    "FIELD64": "53",
    "FIELD65": "53",
    "FIELD66": "42",
    "FIELD67": "36",
    "FIELD68": "11",
    "FIELD69": "12.231",
    "FIELD70": "2.934",
    "FIELD71": "182",
    "FIELD72": "681",
    "FIELD73": "346",
    "FIELD74": "335",
    "FIELD75": "22.451",
    "FIELD76": "3.742",
    "FIELD77": "73",
    "FIELD78": ".401",
    "FIELD79": "12",
    "FIELD80": "5",
    "FIELD81": "5",
    "FIELD82": "8",
    "FIELD83": "5.272",
    "FIELD84": ".508",
    "FIELD85": "93",
    "FIELD86": ".511",
    "FIELD87": ".570",
    "FIELD88": ".086",
    "FIELD89": ".290",
    "FIELD90": "1.385",
    "FIELD91": "12",
    "FIELD92": "24",
    "FIELD93": "6",
    "FIELD94": "5",
    "FIELD95": "10",
    "FIELD96": "129",
    "FIELD97": "72",
    "FIELD98": ".106",
    "FIELD99": "8.308",
    "FIELD100": ".198",
    "FIELD101": ".857",
    "FIELD102": ".484",
    "FIELD103": ".516",
    "FIELD104": ".391",
    "FIELD105": ".609",
    "FIELD106": "16",
    "FIELD107": "26",
    "FIELD108": ".615",
    "FIELD109": ".364",
    "FIELD110": "0",
    "FIELD111": ".505",
    "FIELD112": "40",
    "FIELD113": "1",
    "FIELD114": "4",
    "FIELD115": "32",
    "FIELD116": "2",
    "FIELD117": ".941",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "83",
    "FIELD137": "16",
    "FIELD138": "65",
    "FIELD139": "2",
    "FIELD140": "7",
    "FIELD141": "0",
    "FIELD142": ".976",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "10",
    "FIELD2": "Jones",
    "FIELD3": "Carson",
    "Offensive Stats": "36",
    "FIELD5": "93",
    "FIELD6": "62",
    "FIELD7": "22",
    "FIELD8": "19",
    "FIELD9": "3",
    "FIELD10": "0",
    "FIELD11": "0",
    "FIELD12": "15",
    "FIELD13": "17",
    "FIELD14": "9",
    "FIELD15": "1",
    "FIELD16": "2",
    "FIELD17": "0",
    "FIELD18": "21",
    "FIELD19": "19",
    "FIELD20": ".355",
    "FIELD21": ".565",
    "FIELD22": ".403",
    "FIELD23": ".968",
    "FIELD24": "4.429",
    "FIELD25": "1.105",
    "FIELD26": ".694",
    "FIELD27": "6",
    "FIELD28": "9",
    "FIELD29": "0",
    "FIELD30": "1.000",
    "FIELD31": "0",
    "FIELD32": "2",
    "FIELD33": "0",
    "FIELD34": "3",
    "FIELD35": "25",
    "FIELD36": ".000",
    "FIELD37": ".389",
    "FIELD38": "344",
    "FIELD39": "3.699",
    "FIELD40": "7",
    "FIELD41": ".075",
    "FIELD42": "10",
    "FIELD43": ".108",
    "FIELD44": "22.73%",
    "FIELD45": "29.55%",
    "FIELD46": "1",
    "FIELD47": "0",
    "FIELD48": "47",
    "FIELD49": "8",
    "FIELD50": "24",
    "FIELD51": "52",
    "FIELD52": "55.91%",
    "FIELD53": ".512",
    "Defensive Stats": "9.1",
    "FIELD55": "7",
    "FIELD56": "0",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "19",
    "FIELD64": "16",
    "FIELD65": "13",
    "FIELD66": "6",
    "FIELD67": "7",
    "FIELD68": "3",
    "FIELD69": "9.750",
    "FIELD70": "2.679",
    "FIELD71": "57",
    "FIELD72": "182",
    "FIELD73": "93",
    "FIELD74": "89",
    "FIELD75": "19.500",
    "FIELD76": "3.193",
    "FIELD77": "34",
    "FIELD78": ".596",
    "FIELD79": "3",
    "FIELD80": "2",
    "FIELD81": "0",
    "FIELD82": "3",
    "FIELD83": "4.138",
    "FIELD84": ".511",
    "FIELD85": "21",
    "FIELD86": ".368",
    "FIELD87": ".667",
    "FIELD88": ".000",
    "FIELD89": ".333",
    "FIELD90": ".643",
    "FIELD91": "5",
    "FIELD92": "2",
    "FIELD93": "2",
    "FIELD94": "2",
    "FIELD95": "2",
    "FIELD96": "48",
    "FIELD97": "14",
    "FIELD98": ".077",
    "FIELD99": "5.250",
    "FIELD100": ".123",
    "FIELD101": "1.167",
    "FIELD102": ".683",
    "FIELD103": ".317",
    "FIELD104": ".366",
    "FIELD105": ".634",
    "FIELD106": "7",
    "FIELD107": "12",
    "FIELD108": ".583",
    "FIELD109": ".396",
    "FIELD110": "0",
    "FIELD111": ".463",
    "FIELD112": "15",
    "FIELD113": "0",
    "FIELD114": "0",
    "FIELD115": "8",
    "FIELD116": "0",
    "FIELD117": "1.000",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "48",
    "FIELD137": "21",
    "FIELD138": "22",
    "FIELD139": "5",
    "FIELD140": "4",
    "FIELD141": "0",
    "FIELD142": ".896",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "12",
    "FIELD2": "Young",
    "FIELD3": "Blake",
    "Offensive Stats": "33",
    "FIELD5": "93",
    "FIELD6": "72",
    "FIELD7": "31",
    "FIELD8": "27",
    "FIELD9": "3",
    "FIELD10": "1",
    "FIELD11": "0",
    "FIELD12": "16",
    "FIELD13": "34",
    "FIELD14": "7",
    "FIELD15": "6",
    "FIELD16": "1",
    "FIELD17": "0",
    "FIELD18": "14",
    "FIELD19": "6",
    "FIELD20": ".431",
    "FIELD21": ".559",
    "FIELD22": ".500",
    "FIELD23": "1.059",
    "FIELD24": "6.643",
    "FIELD25": "2.333",
    "FIELD26": ".917",
    "FIELD27": "2",
    "FIELD28": "15",
    "FIELD29": "5",
    "FIELD30": ".750",
    "FIELD31": "1",
    "FIELD32": "1",
    "FIELD33": "0",
    "FIELD34": "4",
    "FIELD35": "36",
    "FIELD36": ".000",
    "FIELD37": ".517",
    "FIELD38": "276",
    "FIELD39": "2.968",
    "FIELD40": "7",
    "FIELD41": ".075",
    "FIELD42": "9",
    "FIELD43": ".097",
    "FIELD44": "16.67%",
    "FIELD45": "45.45%",
    "FIELD46": "0",
    "FIELD47": "0",
    "FIELD48": "29",
    "FIELD49": "5",
    "FIELD50": "29",
    "FIELD51": "49",
    "FIELD52": "52.69%",
    "FIELD53": ".470",
    "Defensive Stats": "0.0",
    "FIELD55": "0",
    "FIELD56": "0",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "0",
    "FIELD64": "0",
    "FIELD65": "0",
    "FIELD66": "0",
    "FIELD67": "0",
    "FIELD68": "0",
    "FIELD69": ".000",
    "FIELD70": ".000",
    "FIELD71": "0",
    "FIELD72": "0",
    "FIELD73": "0",
    "FIELD74": "0",
    "FIELD75": ".000",
    "FIELD76": ".000",
    "FIELD77": "0",
    "FIELD78": ".000",
    "FIELD79": "0",
    "FIELD80": "0",
    "FIELD81": "0",
    "FIELD82": "0",
    "FIELD83": ".000",
    "FIELD84": ".000",
    "FIELD85": "0",
    "FIELD86": ".000",
    "FIELD87": ".000",
    "FIELD88": ".000",
    "FIELD89": ".000",
    "FIELD90": ".000",
    "FIELD91": "0",
    "FIELD92": "0",
    "FIELD93": "0",
    "FIELD94": "0",
    "FIELD95": "0",
    "FIELD96": "0",
    "FIELD97": "0",
    "FIELD98": ".000",
    "FIELD99": ".000",
    "FIELD100": ".000",
    "FIELD101": ".000",
    "FIELD102": ".000",
    "FIELD103": ".000",
    "FIELD104": ".000",
    "FIELD105": ".000",
    "FIELD106": "0",
    "FIELD107": "0",
    "FIELD108": ".000",
    "FIELD109": ".000",
    "FIELD110": "0",
    "FIELD111": ".000",
    "FIELD112": "0",
    "FIELD113": "0",
    "FIELD114": "0",
    "FIELD115": "0",
    "FIELD116": "0",
    "FIELD117": ".000",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "76",
    "FIELD137": "15",
    "FIELD138": "58",
    "FIELD139": "3",
    "FIELD140": "3",
    "FIELD141": "0",
    "FIELD142": ".961",
    "FIELD143": "32.1",
    "FIELD144": "1",
    "FIELD145": "27",
    "FIELD146": "3",
    "FIELD147": ".100",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "23",
    "FIELD2": "Baldwin",
    "FIELD3": "Tyson",
    "Offensive Stats": "31",
    "FIELD5": "79",
    "FIELD6": "49",
    "FIELD7": "9",
    "FIELD8": "9",
    "FIELD9": "0",
    "FIELD10": "0",
    "FIELD11": "0",
    "FIELD12": "10",
    "FIELD13": "18",
    "FIELD14": "12",
    "FIELD15": "1",
    "FIELD16": "1",
    "FIELD17": "0",
    "FIELD18": "17",
    "FIELD19": "15",
    "FIELD20": ".184",
    "FIELD21": ".487",
    "FIELD22": ".184",
    "FIELD23": ".671",
    "FIELD24": "4.647",
    "FIELD25": "1.133",
    "FIELD26": ".694",
    "FIELD27": "4",
    "FIELD28": "10",
    "FIELD29": "2",
    "FIELD30": ".833",
    "FIELD31": "1",
    "FIELD32": "0",
    "FIELD33": "0",
    "FIELD34": "0",
    "FIELD35": "9",
    "FIELD36": ".000",
    "FIELD37": ".192",
    "FIELD38": "293",
    "FIELD39": "3.709",
    "FIELD40": "11",
    "FIELD41": ".139",
    "FIELD42": "17",
    "FIELD43": ".215",
    "FIELD44": "33.33%",
    "FIELD45": "36.11%",
    "FIELD46": "1",
    "FIELD47": "0",
    "FIELD48": "32",
    "FIELD49": "4",
    "FIELD50": "10",
    "FIELD51": "36",
    "FIELD52": "45.57%",
    "FIELD53": ".265",
    "Defensive Stats": "6.1",
    "FIELD55": "6",
    "FIELD56": "0",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "15",
    "FIELD64": "11",
    "FIELD65": "11",
    "FIELD66": "7",
    "FIELD67": "7",
    "FIELD68": "3",
    "FIELD69": "12.158",
    "FIELD70": "3.474",
    "FIELD71": "38",
    "FIELD72": "144",
    "FIELD73": "75",
    "FIELD74": "69",
    "FIELD75": "22.737",
    "FIELD76": "3.789",
    "FIELD77": "12",
    "FIELD78": ".316",
    "FIELD79": "1",
    "FIELD80": "1",
    "FIELD81": "1",
    "FIELD82": "2",
    "FIELD83": "5.002",
    "FIELD84": ".521",
    "FIELD85": "24",
    "FIELD86": ".632",
    "FIELD87": ".375",
    "FIELD88": ".083",
    "FIELD89": ".458",
    "FIELD90": "1.105",
    "FIELD91": "1",
    "FIELD92": "2",
    "FIELD93": "2",
    "FIELD94": "0",
    "FIELD95": "2",
    "FIELD96": "28",
    "FIELD97": "16",
    "FIELD98": ".111",
    "FIELD99": "7.737",
    "FIELD100": ".184",
    "FIELD101": "1.000",
    "FIELD102": ".571",
    "FIELD103": ".429",
    "FIELD104": ".476",
    "FIELD105": ".524",
    "FIELD106": "2",
    "FIELD107": "3",
    "FIELD108": ".667",
    "FIELD109": ".536",
    "FIELD110": "0",
    "FIELD111": ".714",
    "FIELD112": "8",
    "FIELD113": "0",
    "FIELD114": "1",
    "FIELD115": "6",
    "FIELD116": "3",
    "FIELD117": ".667",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "72",
    "FIELD137": "14",
    "FIELD138": "52",
    "FIELD139": "6",
    "FIELD140": "3",
    "FIELD141": "0",
    "FIELD142": ".917",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "26",
    "FIELD2": "Lindsay",
    "FIELD3": "Kamden",
    "Offensive Stats": "32",
    "FIELD5": "75",
    "FIELD6": "55",
    "FIELD7": "14",
    "FIELD8": "14",
    "FIELD9": "0",
    "FIELD10": "0",
    "FIELD11": "0",
    "FIELD12": "7",
    "FIELD13": "11",
    "FIELD14": "2",
    "FIELD15": "1",
    "FIELD16": "0",
    "FIELD17": "0",
    "FIELD18": "18",
    "FIELD19": "18",
    "FIELD20": ".255",
    "FIELD21": ".453",
    "FIELD22": ".255",
    "FIELD23": ".708",
    "FIELD24": "4.167",
    "FIELD25": "1.000",
    "FIELD26": ".673",
    "FIELD27": "6",
    "FIELD28": "1",
    "FIELD29": "0",
    "FIELD30": "1.000",
    "FIELD31": "2",
    "FIELD32": "1",
    "FIELD33": "0",
    "FIELD34": "0",
    "FIELD35": "14",
    "FIELD36": ".000",
    "FIELD37": ".182",
    "FIELD38": "303",
    "FIELD39": "4.040",
    "FIELD40": "11",
    "FIELD41": ".147",
    "FIELD42": "15",
    "FIELD43": ".200",
    "FIELD44": "29.73%",
    "FIELD45": "48.65%",
    "FIELD46": "0",
    "FIELD47": "0",
    "FIELD48": "54",
    "FIELD49": "3",
    "FIELD50": "10",
    "FIELD51": "37",
    "FIELD52": "49.33%",
    "FIELD53": ".378",
    "Defensive Stats": "14.2",
    "FIELD55": "9",
    "FIELD56": "1",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "39",
    "FIELD64": "31",
    "FIELD65": "25",
    "FIELD66": "15",
    "FIELD67": "9",
    "FIELD68": "4",
    "FIELD69": "11.932",
    "FIELD70": "3.682",
    "FIELD71": "96",
    "FIELD72": "304",
    "FIELD73": "163",
    "FIELD74": "141",
    "FIELD75": "20.727",
    "FIELD76": "3.167",
    "FIELD77": "52",
    "FIELD78": ".542",
    "FIELD79": "4",
    "FIELD80": "1",
    "FIELD81": "1",
    "FIELD82": "3",
    "FIELD83": "5.314",
    "FIELD84": ".536",
    "FIELD85": "51",
    "FIELD86": ".531",
    "FIELD87": ".353",
    "FIELD88": ".078",
    "FIELD89": ".569",
    "FIELD90": "1.023",
    "FIELD91": "4",
    "FIELD92": "9",
    "FIELD93": "3",
    "FIELD94": "3",
    "FIELD95": "6",
    "FIELD96": "77",
    "FIELD97": "26",
    "FIELD98": ".086",
    "FIELD99": "4.295",
    "FIELD100": ".094",
    "FIELD101": ".600",
    "FIELD102": ".456",
    "FIELD103": ".544",
    "FIELD104": ".471",
    "FIELD105": ".529",
    "FIELD106": "12",
    "FIELD107": "12",
    "FIELD108": "1.000",
    "FIELD109": ".506",
    "FIELD110": "0",
    "FIELD111": ".574",
    "FIELD112": "19",
    "FIELD113": "1",
    "FIELD114": "3",
    "FIELD115": "7",
    "FIELD116": "1",
    "FIELD117": ".875",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "72",
    "FIELD137": "9",
    "FIELD138": "57",
    "FIELD139": "6",
    "FIELD140": "3",
    "FIELD141": "0",
    "FIELD142": ".917",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "27",
    "FIELD2": "Burns",
    "FIELD3": "Zachary",
    "Offensive Stats": "35",
    "FIELD5": "75",
    "FIELD6": "63",
    "FIELD7": "24",
    "FIELD8": "17",
    "FIELD9": "6",
    "FIELD10": "1",
    "FIELD11": "0",
    "FIELD12": "16",
    "FIELD13": "25",
    "FIELD14": "2",
    "FIELD15": "0",
    "FIELD16": "2",
    "FIELD17": "0",
    "FIELD18": "10",
    "FIELD19": "5",
    "FIELD20": ".381",
    "FIELD21": ".480",
    "FIELD22": ".508",
    "FIELD23": ".988",
    "FIELD24": "7.500",
    "FIELD25": "2.000",
    "FIELD26": ".921",
    "FIELD27": "3",
    "FIELD28": "4",
    "FIELD29": "1",
    "FIELD30": ".800",
    "FIELD31": "1",
    "FIELD32": "3",
    "FIELD33": "0",
    "FIELD34": "7",
    "FIELD35": "32",
    "FIELD36": ".000",
    "FIELD37": ".375",
    "FIELD38": "226",
    "FIELD39": "3.013",
    "FIELD40": "3",
    "FIELD41": ".040",
    "FIELD42": "6",
    "FIELD43": ".080",
    "FIELD44": "27.59%",
    "FIELD45": "39.66%",
    "FIELD46": "0",
    "FIELD47": "0",
    "FIELD48": "38",
    "FIELD49": "2",
    "FIELD50": "29",
    "FIELD51": "44",
    "FIELD52": "58.67%",
    "FIELD53": ".414",
    "Defensive Stats": "21.1",
    "FIELD55": "11",
    "FIELD56": "2",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "36",
    "FIELD64": "26",
    "FIELD65": "25",
    "FIELD66": "23",
    "FIELD67": "20",
    "FIELD68": "4",
    "FIELD69": "8.203",
    "FIELD70": "2.766",
    "FIELD71": "120",
    "FIELD72": "414",
    "FIELD73": "214",
    "FIELD74": "200",
    "FIELD75": "19.406",
    "FIELD76": "3.450",
    "FIELD77": "53",
    "FIELD78": ".442",
    "FIELD79": "7",
    "FIELD80": "6",
    "FIELD81": "2",
    "FIELD82": "6",
    "FIELD83": "4.532",
    "FIELD84": ".517",
    "FIELD85": "65",
    "FIELD86": ".542",
    "FIELD87": ".492",
    "FIELD88": ".046",
    "FIELD89": ".415",
    "FIELD90": "1.078",
    "FIELD91": "8",
    "FIELD92": "9",
    "FIELD93": "3",
    "FIELD94": "2",
    "FIELD95": "6",
    "FIELD96": "92",
    "FIELD97": "37",
    "FIELD98": ".089",
    "FIELD99": "6.562",
    "FIELD100": ".167",
    "FIELD101": ".870",
    "FIELD102": ".603",
    "FIELD103": ".397",
    "FIELD104": ".507",
    "FIELD105": ".493",
    "FIELD106": "15",
    "FIELD107": "16",
    "FIELD108": ".938",
    "FIELD109": ".391",
    "FIELD110": "0",
    "FIELD111": ".500",
    "FIELD112": "26",
    "FIELD113": "0",
    "FIELD114": "2",
    "FIELD115": "13",
    "FIELD116": "3",
    "FIELD117": ".812",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "32",
    "FIELD137": "13",
    "FIELD138": "13",
    "FIELD139": "6",
    "FIELD140": "1",
    "FIELD141": "0",
    "FIELD142": ".812",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "34",
    "FIELD2": "Kerekes",
    "FIELD3": "Ryker",
    "Offensive Stats": "33",
    "FIELD5": "92",
    "FIELD6": "72",
    "FIELD7": "27",
    "FIELD8": "24",
    "FIELD9": "3",
    "FIELD10": "0",
    "FIELD11": "0",
    "FIELD12": "17",
    "FIELD13": "23",
    "FIELD14": "4",
    "FIELD15": "1",
    "FIELD16": "2",
    "FIELD17": "0",
    "FIELD18": "15",
    "FIELD19": "5",
    "FIELD20": ".375",
    "FIELD21": ".500",
    "FIELD22": ".417",
    "FIELD23": ".917",
    "FIELD24": "6.133",
    "FIELD25": "3.000",
    "FIELD26": ".931",
    "FIELD27": "4",
    "FIELD28": "14",
    "FIELD29": "3",
    "FIELD30": ".824",
    "FIELD31": "2",
    "FIELD32": "1",
    "FIELD33": "0",
    "FIELD34": "3",
    "FIELD35": "30",
    "FIELD36": ".000",
    "FIELD37": ".455",
    "FIELD38": "276",
    "FIELD39": "3.000",
    "FIELD40": "4",
    "FIELD41": ".043",
    "FIELD42": "8",
    "FIELD43": ".087",
    "FIELD44": "27.94%",
    "FIELD45": "29.41%",
    "FIELD46": "0",
    "FIELD47": "1",
    "FIELD48": "42",
    "FIELD49": "4",
    "FIELD50": "35",
    "FIELD51": "57",
    "FIELD52": "61.96%",
    "FIELD53": ".397",
    "Defensive Stats": "42.1",
    "FIELD55": "18",
    "FIELD56": "14",
    "FIELD57": "0",
    "FIELD58": "1",
    "FIELD59": "1",
    "FIELD60": "1",
    "FIELD61": "0",
    "FIELD62": "1.000",
    "FIELD63": "80",
    "FIELD64": "54",
    "FIELD65": "50",
    "FIELD66": "31",
    "FIELD67": "54",
    "FIELD68": "5",
    "FIELD69": "8.268",
    "FIELD70": "2.622",
    "FIELD71": "233",
    "FIELD72": "831",
    "FIELD73": "500",
    "FIELD74": "331",
    "FIELD75": "19.630",
    "FIELD76": "3.567",
    "FIELD77": "118",
    "FIELD78": ".506",
    "FIELD79": "28",
    "FIELD80": "8",
    "FIELD81": "1",
    "FIELD82": "11",
    "FIELD83": "3.413",
    "FIELD84": ".602",
    "FIELD85": "125",
    "FIELD86": ".536",
    "FIELD87": ".552",
    "FIELD88": ".056",
    "FIELD89": ".384",
    "FIELD90": ".732",
    "FIELD91": "21",
    "FIELD92": "12",
    "FIELD93": "7",
    "FIELD94": "4",
    "FIELD95": "12",
    "FIELD96": "197",
    "FIELD97": "106",
    "FIELD98": ".128",
    "FIELD99": "8.929",
    "FIELD100": ".232",
    "FIELD101": "1.742",
    "FIELD102": ".545",
    "FIELD103": ".455",
    "FIELD104": ".437",
    "FIELD105": ".563",
    "FIELD106": "28",
    "FIELD107": "31",
    "FIELD108": ".903",
    "FIELD109": ".406",
    "FIELD110": "3",
    "FIELD111": ".550",
    "FIELD112": "48",
    "FIELD113": "0",
    "FIELD114": "6",
    "FIELD115": "23",
    "FIELD116": "3",
    "FIELD117": ".885",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "58",
    "FIELD137": "35",
    "FIELD138": "19",
    "FIELD139": "4",
    "FIELD140": "4",
    "FIELD141": "0",
    "FIELD142": ".931",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "42",
    "FIELD2": "Childs",
    "FIELD3": "Kalen",
    "Offensive Stats": "36",
    "FIELD5": "75",
    "FIELD6": "64",
    "FIELD7": "20",
    "FIELD8": "20",
    "FIELD9": "0",
    "FIELD10": "0",
    "FIELD11": "0",
    "FIELD12": "13",
    "FIELD13": "20",
    "FIELD14": "2",
    "FIELD15": "0",
    "FIELD16": "0",
    "FIELD17": "0",
    "FIELD18": "9",
    "FIELD19": "14",
    "FIELD20": ".312",
    "FIELD21": ".413",
    "FIELD22": ".312",
    "FIELD23": ".726",
    "FIELD24": "8.333",
    "FIELD25": ".643",
    "FIELD26": ".781",
    "FIELD27": "5",
    "FIELD28": "1",
    "FIELD29": "0",
    "FIELD30": "1.000",
    "FIELD31": "0",
    "FIELD32": "0",
    "FIELD33": "0",
    "FIELD34": "0",
    "FIELD35": "20",
    "FIELD36": ".000",
    "FIELD37": ".222",
    "FIELD38": "265",
    "FIELD39": "3.533",
    "FIELD40": "3",
    "FIELD41": ".040",
    "FIELD42": "8",
    "FIELD43": ".107",
    "FIELD44": "18.00%",
    "FIELD45": "56.00%",
    "FIELD46": "0",
    "FIELD47": "0",
    "FIELD48": "48",
    "FIELD49": "4",
    "FIELD50": "20",
    "FIELD51": "33",
    "FIELD52": "44.00%",
    "FIELD53": ".400",
    "Defensive Stats": "1.2",
    "FIELD55": "3",
    "FIELD56": "0",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "6",
    "FIELD64": "6",
    "FIELD65": "6",
    "FIELD66": "3",
    "FIELD67": "1",
    "FIELD68": "1",
    "FIELD69": "25.200",
    "FIELD70": "5.400",
    "FIELD71": "14",
    "FIELD72": "58",
    "FIELD73": "28",
    "FIELD74": "30",
    "FIELD75": "34.800",
    "FIELD76": "4.143",
    "FIELD77": "5",
    "FIELD78": ".357",
    "FIELD79": "0",
    "FIELD80": "0",
    "FIELD81": "0",
    "FIELD82": "0",
    "FIELD83": "8.282",
    "FIELD84": ".483",
    "FIELD85": "9",
    "FIELD86": ".643",
    "FIELD87": ".333",
    "FIELD88": ".111",
    "FIELD89": ".556",
    "FIELD90": "1.800",
    "FIELD91": "0",
    "FIELD92": "3",
    "FIELD93": "1",
    "FIELD94": "1",
    "FIELD95": "2",
    "FIELD96": "10",
    "FIELD97": "3",
    "FIELD98": ".052",
    "FIELD99": "4.200",
    "FIELD100": ".071",
    "FIELD101": ".333",
    "FIELD102": ".222",
    "FIELD103": ".778",
    "FIELD104": ".444",
    "FIELD105": ".556",
    "FIELD106": "1",
    "FIELD107": "2",
    "FIELD108": ".500",
    "FIELD109": ".600",
    "FIELD110": "0",
    "FIELD111": ".667",
    "FIELD112": "4",
    "FIELD113": "0",
    "FIELD114": "1",
    "FIELD115": "3",
    "FIELD116": "0",
    "FIELD117": "1.000",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "18",
    "FIELD137": "5",
    "FIELD138": "12",
    "FIELD139": "1",
    "FIELD140": "1",
    "FIELD141": "0",
    "FIELD142": ".944",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "45",
    "FIELD2": "Mohlman",
    "FIELD3": "Austin",
    "Offensive Stats": "27",
    "FIELD5": "74",
    "FIELD6": "58",
    "FIELD7": "27",
    "FIELD8": "18",
    "FIELD9": "9",
    "FIELD10": "0",
    "FIELD11": "0",
    "FIELD12": "23",
    "FIELD13": "13",
    "FIELD14": "11",
    "FIELD15": "0",
    "FIELD16": "1",
    "FIELD17": "0",
    "FIELD18": "5",
    "FIELD19": "11",
    "FIELD20": ".466",
    "FIELD21": ".581",
    "FIELD22": ".621",
    "FIELD23": "1.202",
    "FIELD24": "14.800",
    "FIELD25": ".455",
    "FIELD26": ".810",
    "FIELD27": "4",
    "FIELD28": "7",
    "FIELD29": "0",
    "FIELD30": "1.000",
    "FIELD31": "1",
    "FIELD32": "0",
    "FIELD33": "0",
    "FIELD34": "9",
    "FIELD35": "36",
    "FIELD36": ".000",
    "FIELD37": ".526",
    "FIELD38": "246",
    "FIELD39": "3.324",
    "FIELD40": "3",
    "FIELD41": ".041",
    "FIELD42": "8",
    "FIELD43": ".108",
    "FIELD44": "25.53%",
    "FIELD45": "31.91%",
    "FIELD46": "0",
    "FIELD47": "0",
    "FIELD48": "30",
    "FIELD49": "9",
    "FIELD50": "25",
    "FIELD51": "36",
    "FIELD52": "48.65%",
    "FIELD53": ".574",
    "Defensive Stats": "27.2",
    "FIELD55": "14",
    "FIELD56": "9",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "57",
    "FIELD64": "52",
    "FIELD65": "50",
    "FIELD66": "29",
    "FIELD67": "31",
    "FIELD68": "3",
    "FIELD69": "12.651",
    "FIELD70": "3.108",
    "FIELD71": "167",
    "FIELD72": "626",
    "FIELD73": "358",
    "FIELD74": "268",
    "FIELD75": "22.627",
    "FIELD76": "3.749",
    "FIELD77": "74",
    "FIELD78": ".443",
    "FIELD79": "11",
    "FIELD80": "8",
    "FIELD81": "3",
    "FIELD82": "7",
    "FIELD83": "4.212",
    "FIELD84": ".572",
    "FIELD85": "95",
    "FIELD86": ".569",
    "FIELD87": ".516",
    "FIELD88": ".063",
    "FIELD89": ".411",
    "FIELD90": "1.048",
    "FIELD91": "14",
    "FIELD92": "17",
    "FIELD93": "5",
    "FIELD94": "2",
    "FIELD95": "8",
    "FIELD96": "135",
    "FIELD97": "56",
    "FIELD98": ".089",
    "FIELD99": "7.843",
    "FIELD100": ".186",
    "FIELD101": "1.069",
    "FIELD102": ".606",
    "FIELD103": ".394",
    "FIELD104": ".462",
    "FIELD105": ".538",
    "FIELD106": "20",
    "FIELD107": "18",
    "FIELD108": "1.111",
    "FIELD109": ".422",
    "FIELD110": "1",
    "FIELD111": ".544",
    "FIELD112": "32",
    "FIELD113": "0",
    "FIELD114": "0",
    "FIELD115": "17",
    "FIELD116": "3",
    "FIELD117": ".850",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "21",
    "FIELD137": "6",
    "FIELD138": "10",
    "FIELD139": "5",
    "FIELD140": "0",
    "FIELD141": "0",
    "FIELD142": ".762",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "47",
    "FIELD2": "Kvenvold",
    "FIELD3": "Bowen",
    "Offensive Stats": "36",
    "FIELD5": "96",
    "FIELD6": "77",
    "FIELD7": "36",
    "FIELD8": "28",
    "FIELD9": "8",
    "FIELD10": "0",
    "FIELD11": "0",
    "FIELD12": "26",
    "FIELD13": "24",
    "FIELD14": "1",
    "FIELD15": "0",
    "FIELD16": "2",
    "FIELD17": "0",
    "FIELD18": "18",
    "FIELD19": "21",
    "FIELD20": ".468",
    "FIELD21": ".573",
    "FIELD22": ".571",
    "FIELD23": "1.144",
    "FIELD24": "5.333",
    "FIELD25": ".857",
    "FIELD26": ".727",
    "FIELD27": "9",
    "FIELD28": "14",
    "FIELD29": "1",
    "FIELD30": ".933",
    "FIELD31": "1",
    "FIELD32": "1",
    "FIELD33": "0",
    "FIELD34": "8",
    "FIELD35": "44",
    "FIELD36": ".000",
    "FIELD37": ".468",
    "FIELD38": "340",
    "FIELD39": "3.542",
    "FIELD40": "7",
    "FIELD41": ".073",
    "FIELD42": "14",
    "FIELD43": ".146",
    "FIELD44": "23.21%",
    "FIELD45": "32.14%",
    "FIELD46": "0",
    "FIELD47": "0",
    "FIELD48": "45",
    "FIELD49": "12",
    "FIELD50": "33",
    "FIELD51": "60",
    "FIELD52": "62.50%",
    "FIELD53": ".643",
    "Defensive Stats": "0.0",
    "FIELD55": "0",
    "FIELD56": "0",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "0",
    "FIELD64": "0",
    "FIELD65": "0",
    "FIELD66": "0",
    "FIELD67": "0",
    "FIELD68": "0",
    "FIELD69": ".000",
    "FIELD70": ".000",
    "FIELD71": "0",
    "FIELD72": "0",
    "FIELD73": "0",
    "FIELD74": "0",
    "FIELD75": ".000",
    "FIELD76": ".000",
    "FIELD77": "0",
    "FIELD78": ".000",
    "FIELD79": "0",
    "FIELD80": "0",
    "FIELD81": "0",
    "FIELD82": "0",
    "FIELD83": ".000",
    "FIELD84": ".000",
    "FIELD85": "0",
    "FIELD86": ".000",
    "FIELD87": ".000",
    "FIELD88": ".000",
    "FIELD89": ".000",
    "FIELD90": ".000",
    "FIELD91": "0",
    "FIELD92": "0",
    "FIELD93": "0",
    "FIELD94": "0",
    "FIELD95": "0",
    "FIELD96": "0",
    "FIELD97": "0",
    "FIELD98": ".000",
    "FIELD99": ".000",
    "FIELD100": ".000",
    "FIELD101": ".000",
    "FIELD102": ".000",
    "FIELD103": ".000",
    "FIELD104": ".000",
    "FIELD105": ".000",
    "FIELD106": "0",
    "FIELD107": "0",
    "FIELD108": ".000",
    "FIELD109": ".000",
    "FIELD110": "0",
    "FIELD111": ".000",
    "FIELD112": "0",
    "FIELD113": "0",
    "FIELD114": "0",
    "FIELD115": "0",
    "FIELD116": "0",
    "FIELD117": ".000",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "174",
    "FIELD137": "17",
    "FIELD138": "151",
    "FIELD139": "6",
    "FIELD140": "0",
    "FIELD141": "0",
    "FIELD142": ".966",
    "FIELD143": "133.2",
    "FIELD144": "3",
    "FIELD145": "97",
    "FIELD146": "12",
    "FIELD147": ".110",
    "FIELD148": "2",
    "FIELD149": "0"
  },
  {
    "FIELD1": "80",
    "FIELD2": "Rice",
    "FIELD3": "Spencer",
    "Offensive Stats": "28",
    "FIELD5": "63",
    "FIELD6": "52",
    "FIELD7": "20",
    "FIELD8": "17",
    "FIELD9": "2",
    "FIELD10": "1",
    "FIELD11": "0",
    "FIELD12": "12",
    "FIELD13": "15",
    "FIELD14": "3",
    "FIELD15": "1",
    "FIELD16": "1",
    "FIELD17": "0",
    "FIELD18": "8",
    "FIELD19": "16",
    "FIELD20": ".385",
    "FIELD21": ".492",
    "FIELD22": ".462",
    "FIELD23": ".954",
    "FIELD24": "7.875",
    "FIELD25": ".500",
    "FIELD26": ".692",
    "FIELD27": "4",
    "FIELD28": "8",
    "FIELD29": "0",
    "FIELD30": "1.000",
    "FIELD31": "0",
    "FIELD32": "1",
    "FIELD33": "0",
    "FIELD34": "3",
    "FIELD35": "24",
    "FIELD36": ".000",
    "FIELD37": ".370",
    "FIELD38": "218",
    "FIELD39": "3.460",
    "FIELD40": "7",
    "FIELD41": ".111",
    "FIELD42": "12",
    "FIELD43": ".190",
    "FIELD44": "13.89%",
    "FIELD45": "33.33%",
    "FIELD46": "0",
    "FIELD47": "0",
    "FIELD48": "31",
    "FIELD49": "6",
    "FIELD50": "22",
    "FIELD51": "38",
    "FIELD52": "60.32%",
    "FIELD53": ".556",
    "Defensive Stats": "0.0",
    "FIELD55": "0",
    "FIELD56": "0",
    "FIELD57": "0",
    "FIELD58": "0",
    "FIELD59": "0",
    "FIELD60": "0",
    "FIELD61": "0",
    "FIELD62": ".000",
    "FIELD63": "0",
    "FIELD64": "0",
    "FIELD65": "0",
    "FIELD66": "0",
    "FIELD67": "0",
    "FIELD68": "0",
    "FIELD69": ".000",
    "FIELD70": ".000",
    "FIELD71": "0",
    "FIELD72": "0",
    "FIELD73": "0",
    "FIELD74": "0",
    "FIELD75": ".000",
    "FIELD76": ".000",
    "FIELD77": "0",
    "FIELD78": ".000",
    "FIELD79": "0",
    "FIELD80": "0",
    "FIELD81": "0",
    "FIELD82": "0",
    "FIELD83": ".000",
    "FIELD84": ".000",
    "FIELD85": "0",
    "FIELD86": ".000",
    "FIELD87": ".000",
    "FIELD88": ".000",
    "FIELD89": ".000",
    "FIELD90": ".000",
    "FIELD91": "0",
    "FIELD92": "0",
    "FIELD93": "0",
    "FIELD94": "0",
    "FIELD95": "0",
    "FIELD96": "0",
    "FIELD97": "0",
    "FIELD98": ".000",
    "FIELD99": ".000",
    "FIELD100": ".000",
    "FIELD101": ".000",
    "FIELD102": ".000",
    "FIELD103": ".000",
    "FIELD104": ".000",
    "FIELD105": ".000",
    "FIELD106": "0",
    "FIELD107": "0",
    "FIELD108": ".000",
    "FIELD109": ".000",
    "FIELD110": "0",
    "FIELD111": ".000",
    "FIELD112": "0",
    "FIELD113": "0",
    "FIELD114": "0",
    "FIELD115": "0",
    "FIELD116": "0",
    "FIELD117": ".000",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "19",
    "FIELD137": "1",
    "FIELD138": "17",
    "FIELD139": "1",
    "FIELD140": "0",
    "FIELD141": "0",
    "FIELD142": ".947",
    "FIELD143": "0.0",
    "FIELD144": "0",
    "FIELD145": "0",
    "FIELD146": "0",
    "FIELD147": ".000",
    "FIELD148": "0",
    "FIELD149": "0"
  },
  {
    "FIELD1": "Team",
    "FIELD2": "Totals",
    "FIELD3": "",
    "Offensive Stats": "36",
    "FIELD5": "999",
    "FIELD6": "768",
    "FIELD7": "294",
    "FIELD8": "238",
    "FIELD9": "51",
    "FIELD10": "5",
    "FIELD11": "0",
    "FIELD12": "205",
    "FIELD13": "251",
    "FIELD14": "63",
    "FIELD15": "14",
    "FIELD16": "13",
    "FIELD17": "0",
    "FIELD18": "164",
    "FIELD19": "154",
    "FIELD20": ".383",
    "FIELD21": ".523",
    "FIELD22": ".462",
    "FIELD23": ".985",
    "FIELD24": "6.091",
    "FIELD25": "1.065",
    "FIELD26": ".799",
    "FIELD27": "57",
    "FIELD28": "114",
    "FIELD29": "14",
    "FIELD30": ".891",
    "FIELD31": "10",
    "FIELD32": "13",
    "FIELD33": "0",
    "FIELD34": "56",
    "FIELD35": "355",
    "FIELD36": ".000",
    "FIELD37": ".383",
    "FIELD38": "3397",
    "FIELD39": "3.400",
    "FIELD40": "78",
    "FIELD41": ".078",
    "FIELD42": "128",
    "FIELD43": ".128",
    "FIELD44": "22.62%",
    "FIELD45": "39.42%",
    "FIELD46": "3",
    "FIELD47": "1",
    "FIELD48": "232",
    "FIELD49": "76",
    "FIELD50": "302",
    "FIELD51": "548",
    "FIELD52": "54.85%",
    "FIELD53": ".478",
    "Defensive Stats": "167.0",
    "FIELD55": "36",
    "FIELD56": "36",
    "FIELD57": "2",
    "FIELD58": "1",
    "FIELD59": "1",
    "FIELD60": "1",
    "FIELD61": "0",
    "FIELD62": "1.000",
    "FIELD63": "337",
    "FIELD64": "290",
    "FIELD65": "271",
    "FIELD66": "177",
    "FIELD67": "176",
    "FIELD68": "40",
    "FIELD69": "11.359",
    "FIELD70": "3.078",
    "FIELD71": "1007",
    "FIELD72": "3592",
    "FIELD73": "1954",
    "FIELD74": "1638",
    "FIELD75": "21.509",
    "FIELD76": "3.567",
    "FIELD77": "469",
    "FIELD78": ".466",
    "FIELD79": "71",
    "FIELD80": "33",
    "FIELD81": "15",
    "FIELD82": "44",
    "FIELD83": "4.613",
    "FIELD84": ".544",
    "FIELD85": "534",
    "FIELD86": ".530",
    "FIELD87": ".498",
    "FIELD88": ".066",
    "FIELD89": ".412",
    "FIELD90": "1.060",
    "FIELD91": "70",
    "FIELD92": "92",
    "FIELD93": "34",
    "FIELD94": "24",
    "FIELD95": "55",
    "FIELD96": "789",
    "FIELD97": "353",
    "FIELD98": ".098",
    "FIELD99": "7.377",
    "FIELD100": ".175",
    "FIELD101": ".994",
    "FIELD102": ".547",
    "FIELD103": ".453",
    "FIELD104": ".435",
    "FIELD105": ".565",
    "FIELD106": "108",
    "FIELD107": "135",
    "FIELD108": ".800",
    "FIELD109": ".427",
    "FIELD110": "4",
    "FIELD111": ".547",
    "FIELD112": "208",
    "FIELD113": "2",
    "FIELD114": "20",
    "FIELD115": "124",
    "FIELD116": "15",
    "FIELD117": ".892",
    "FIELD118": "0",
    "FIELD119": "0",
    "FIELD120": ".000",
    "FIELD121": "0",
    "FIELD122": "0",
    "FIELD123": ".000",
    "FIELD124": "0",
    "FIELD125": "0",
    "FIELD126": ".000",
    "FIELD127": "0",
    "FIELD128": "0",
    "FIELD129": ".000",
    "FIELD130": "0",
    "FIELD131": "0",
    "FIELD132": ".000",
    "FIELD133": "0",
    "FIELD134": "0",
    "FIELD135": ".000",
    "FIELD136": "724",
    "FIELD137": "173",
    "FIELD138": "501",
    "FIELD139": "50",
    "FIELD140": "15",
    "FIELD141": "0",
    "FIELD142": ".931",
    "FIELD143": "167.0",
    "FIELD144": "4",
    "FIELD145": "124",
    "FIELD146": "15",
    "FIELD147": ".108",
    "FIELD148": "2",
    "FIELD149": "0"
  },
  {
    "FIELD1": "Glossary:",
    "FIELD2": "",
    "FIELD3": "",
    "Offensive Stats": "",
    "FIELD5": "",
    "FIELD6": "",
    "FIELD7": "",
    "FIELD8": "",
    "FIELD9": "",
    "FIELD10": "",
    "FIELD11": "",
    "FIELD12": "",
    "FIELD13": "",
    "FIELD14": "",
    "FIELD15": "",
    "FIELD16": "",
    "FIELD17": "",
    "FIELD18": "",
    "FIELD19": "",
    "FIELD20": "",
    "FIELD21": "",
    "FIELD22": "",
    "FIELD23": "",
    "FIELD24": "",
    "FIELD25": "",
    "FIELD26": "",
    "FIELD27": "",
    "FIELD28": "",
    "FIELD29": "",
    "FIELD30": "",
    "FIELD31": "",
    "FIELD32": "",
    "FIELD33": "",
    "FIELD34": "",
    "FIELD35": "",
    "FIELD36": "",
    "FIELD37": "",
    "FIELD38": "",
    "FIELD39": "",
    "FIELD40": "",
    "FIELD41": "",
    "FIELD42": "",
    "FIELD43": "",
    "FIELD44": "",
    "FIELD45": "",
    "FIELD46": "",
    "FIELD47": "",
    "FIELD48": "",
    "FIELD49": "",
    "FIELD50": "",
    "FIELD51": "",
    "FIELD52": "",
    "FIELD53": "",
    "Defensive Stats": "",
    "FIELD55": "",
    "FIELD56": "",
    "FIELD57": "",
    "FIELD58": "",
    "FIELD59": "",
    "FIELD60": "",
    "FIELD61": "",
    "FIELD62": "",
    "FIELD63": "",
    "FIELD64": "",
    "FIELD65": "",
    "FIELD66": "",
    "FIELD67": "",
    "FIELD68": "",
    "FIELD69": "",
    "FIELD70": "",
    "FIELD71": "",
    "FIELD72": "",
    "FIELD73": "",
    "FIELD74": "",
    "FIELD75": "",
    "FIELD76": "",
    "FIELD77": "",
    "FIELD78": "",
    "FIELD79": "",
    "FIELD80": "",
    "FIELD81": "",
    "FIELD82": "",
    "FIELD83": "",
    "FIELD84": "",
    "FIELD85": "",
    "FIELD86": "",
    "FIELD87": "",
    "FIELD88": "",
    "FIELD89": "",
    "FIELD90": "",
    "FIELD91": "",
    "FIELD92": "",
    "FIELD93": "",
    "FIELD94": "",
    "FIELD95": "",
    "FIELD96": "",
    "FIELD97": "",
    "FIELD98": "",
    "FIELD99": "",
    "FIELD100": "",
    "FIELD101": "",
    "FIELD102": "",
    "FIELD103": "",
    "FIELD104": "",
    "FIELD105": "",
    "FIELD106": "",
    "FIELD107": "",
    "FIELD108": "",
    "FIELD109": "",
    "FIELD110": "",
    "FIELD111": "",
    "FIELD112": "",
    "FIELD113": "",
    "FIELD114": "",
    "FIELD115": "",
    "FIELD116": "",
    "FIELD117": "",
    "FIELD118": "",
    "FIELD119": "",
    "FIELD120": "",
    "FIELD121": "",
    "FIELD122": "",
    "FIELD123": "",
    "FIELD124": "",
    "FIELD125": "",
    "FIELD126": "",
    "FIELD127": "",
    "FIELD128": "",
    "FIELD129": "",
    "FIELD130": "",
    "FIELD131": "",
    "FIELD132": "",
    "FIELD133": "",
    "FIELD134": "",
    "FIELD135": "",
    "FIELD136": "",
    "FIELD137": "",
    "FIELD138": "",
    "FIELD139": "",
    "FIELD140": "",
    "FIELD141": "",
    "FIELD142": "",
    "FIELD143": "",
    "FIELD144": "",
    "FIELD145": "",
    "FIELD146": "",
    "FIELD147": "",
    "FIELD148": "",
    "FIELD149": ""
  },
  {
    "FIELD1": "",
    "FIELD2": "*",
    "FIELD3": "*",
    "Offensive Stats": "GP = Games played",
    "FIELD5": "PA = Plate appearances",
    "FIELD6": "AB = At bats",
    "FIELD7": "H = Hits",
    "FIELD8": "1B = Singles",
    "FIELD9": "2B = Doubles",
    "FIELD10": "3B = Triples",
    "FIELD11": "HR = Home runs",
    "FIELD12": "RBI = Runs batted in",
    "FIELD13": "R = Runs scored",
    "FIELD14": "HBP = Hit by pitch",
    "FIELD15": "ROE = Reached on error",
    "FIELD16": "FC = Hit into fielder's choice",
    "FIELD17": "CI = Batter advances on catcher's interference",
    "FIELD18": "BB = Base on balls (walks)",
    "FIELD19": "SO = Strikeouts",
    "FIELD20": "AVG = Batting average (season)",
    "FIELD21": "OBP = On-base percentage (season)",
    "FIELD22": "SLG = Slugging percentage",
    "FIELD23": "OPS = On-base percentage plus slugging percentage (season)",
    "FIELD24": "PA/BB = Plate appearances per walk",
    "FIELD25": "BB/K = Walks per strikeout",
    "FIELD26": "C% = Contact rate",
    "FIELD27": "K-L = Strikeouts Looking",
    "FIELD28": "SB = Stolen bases",
    "FIELD29": "CS = Caught stealing",
    "FIELD30": "SB% = Stolen base percentage",
    "FIELD31": "PIK = Picked off",
    "FIELD32": "GIDP = Hit into double play",
    "FIELD33": "GITP = Hit into triple play",
    "FIELD34": "XBH = Extra-base hits",
    "FIELD35": "TB = Total bases",
    "FIELD36": "AB/HR = At bats per home run",
    "FIELD37": "BA/RISP = Batting average with runners in scoring position",
    "FIELD38": "PS = Pitches seen",
    "FIELD39": "PS/PA = Pitches seen per plate appearance",
    "FIELD40": "2S+3 = Plate appearances in which batter sees 3+ pitches after 2 strikes",
    "FIELD41": "2S+3% = % of plate appearances in which batter sees 3+ pitches after 2 strikes",
    "FIELD42": "6+ = Plate appearances with 6+ pitches",
    "FIELD43": "6+% = % of plate appearances of 6+ pitches",
    "FIELD44": "FLB% = Fly ball percentage",
    "FIELD45": "GB% = Ground ball percentage",
    "FIELD46": "SAC = Sacrifice hits & bunts",
    "FIELD47": "SF = Sacrifice flies",
    "FIELD48": "LOB = Runners left on base when batter is out",
    "FIELD49": "2OUTRBI = 2-out RBI",
    "FIELD50": "HHB = Hard hit balls: Total line drives and hard ground balls",
    "FIELD51": "QAB = Quality at bats (any one of: 3 pitches after 2 strikes, 6+ pitch ABs, XBH, HHB, BB, SAC Bunt, SAC Fly)",
    "FIELD52": "QAB% = Quality at bats per plate appearance",
    "FIELD53": "BABIP = Batting average on balls in play",
    "Defensive Stats": "IP = Innings Pitched",
    "FIELD55": "GP = Games pitched",
    "FIELD56": "GS = Games started as the pitcher",
    "FIELD57": "W = Wins",
    "FIELD58": "L = Losses",
    "FIELD59": "SV = Saves",
    "FIELD60": "SVO = Save opportunities",
    "FIELD61": "BS = Blown saves",
    "FIELD62": "SV% = Save percentage",
    "FIELD63": "H = Hits allowed",
    "FIELD64": "R = Runs allowed",
    "FIELD65": "ER = Earned runs allowed",
    "FIELD66": "BB = Base on balls (walks)",
    "FIELD67": "SO = Strikeouts",
    "FIELD68": "HBP = Hit batters",
    "FIELD69": "ERA = Earned run average (season)",
    "FIELD70": "WHIP = Walks plus hits per innings pitched",
    "FIELD71": "BF = Total batters faced",
    "FIELD72": "#P = Total pitches",
    "FIELD73": "TS = Total strikes",
    "FIELD74": "TB = Total balls",
    "FIELD75": "P/IP = Pitches per inning",
    "FIELD76": "P/BF = Pitches per batter faced",
    "FIELD77": "<3 = Batters on or out in three pitches or less",
    "FIELD78": "<3% = % of batters on or out in three pitches or less",
    "FIELD79": "LOO = Leadoff out (1st batter of inning)",
    "FIELD80": "1ST2OUT = Innings with 1st 2 batters out",
    "FIELD81": "123INN =  1-2-3 Innings",
    "FIELD82": "<13 = Innings of 13 pitches or fewer",
    "FIELD83": "FIP = Fielding Independent Pitching",
    "FIELD84": "S% = Strike percentage",
    "FIELD85": "FPS = First pitch strikes",
    "FIELD86": "FPS% = First pitch strike percentage",
    "FIELD87": "FPSO% = % of FPS at-bats that result in an out",
    "FIELD88": "FPSW% = % of FPS at-bats that result in a walk",
    "FIELD89": "FPSH% = % of FPS at-bats that result in a hit",
    "FIELD90": "BB/INN = Walks per inning",
    "FIELD91": "0BBINN = Zero-walk innings",
    "FIELD92": "BBS = Walks that score",
    "FIELD93": "LOBB = Leadoff walk (1st batter of inning)",
    "FIELD94": "LOBBS = Leadoff walk that scored (1st batter of inning)",
    "FIELD95": "WP = Wild pitches",
    "FIELD96": "ABA = At Bats against",
    "FIELD97": "SM = Opposing batter swings-and-misses",
    "FIELD98": "SM% = % of total pitches that are swings and misses",
    "FIELD99": "K/G = Strikeouts per regulation game",
    "FIELD100": "K/BF = Strikeouts per batter faced",
    "FIELD101": "K/BB = Strikeouts per walk",
    "FIELD102": "WEAK% = % of batted balls weakly hit (fly balls and ground balls)",
    "FIELD103": "HHB% = % of batted balls that are line drives or hard ground balls",
    "FIELD104": "GB% = % of all batted balls hit on the ground",
    "FIELD105": "FLB% = % of batted balls that are hit in the air",
    "FIELD106": "GO = Ground outs",
    "FIELD107": "FO = Fly outs",
    "FIELD108": "GO/FO = Ratio of ground outs to fly outs",
    "FIELD109": "BAA = Opponent batting average",
    "FIELD110": "HR = Home runs allowed",
    "FIELD111": "BABIP = Opponent batting average on balls in play",
    "FIELD112": "LOB = Runners left on base",
    "FIELD113": "BK = Balks",
    "FIELD114": "PIK = Runners picked off",
    "FIELD115": "SB = Stolen bases allowed",
    "FIELD116": "CS = Runners caught stealing",
    "FIELD117": "SB% = Opponent stolen base percentage",
    "FIELD118": "FB = Number of pitches thrown as Fastballs",
    "FIELD119": "FBS = Number of Fastballs thrown for strikes",
    "FIELD120": "FBS% = Percentage of Fastballs thrown for strikes",
    "FIELD121": "CT = Number of pitches thrown as Cutters",
    "FIELD122": "CTS = Number of Cutters thrown for strikes",
    "FIELD123": "CTS% = Percentage of Cutters thrown for strikes",
    "FIELD124": "CB = Number of pitches thrown as Curveballs",
    "FIELD125": "CBS = Number of Curveballs thrown for strikes",
    "FIELD126": "CBS% = Percentage of Curveballs thrown for strikes",
    "FIELD127": "SL = Number of pitches thrown as Sliders",
    "FIELD128": "SLS = Number of Sliders thrown for strikes",
    "FIELD129": "SLS% = Percentage of Sliders thrown for strikes",
    "FIELD130": "CH = Number of pitches thrown as Changeups",
    "FIELD131": "CHS = Number of Changeups thrown for strikes",
    "FIELD132": "CHS% = Percentage of Changeups thrown for strikes",
    "FIELD133": "OS = Number of pitches thrown Offspeed (Curveball, Screwball, Changeup)",
    "FIELD134": "OSS = Number of pitches thrown Offspeed for strikes",
    "FIELD135": "OSS% = Percentage of Offspeed pitches thrown as strikes",
    "FIELD136": "TC = Total Chances ",
    "FIELD137": "A = Assists",
    "FIELD138": "PO = Putouts",
    "FIELD139": "E = Errors",
    "FIELD140": "DP = Double Plays",
    "FIELD141": "TP = Triple Plays",
    "FIELD142": "FPCT = Fielding Percentage",
    "FIELD143": "cINN = Innings caught",
    "FIELD144": "cPB = Passed balls allowed",
    "FIELD145": "cSB = Stolen bases allowed",
    "FIELD146": "cCS = Runners caught stealing",
    "FIELD147": "cCS% = Runners caught stealing percentage",
    "FIELD148": "cPIK = Runners picked off",
    "FIELD149": "cCI = Batter advances on catcher's interference"
  }
 ]
export default gameChangerData;