import React, { useEffect, useState } from "react";
import "./DropdownMobile.css";
import RZBLogo from "../../Images/razorbackLogoFace.png";
import { useStateValue } from "../../assets/stateProvider";

function Dropdown() {
  const [isActive, setActive] = useState(false);
  const [{ userData }, dispatch] = useStateValue();

  useEffect(() => {
    if (userData === null) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [userData]);

  const handleSetActive = (e) => {
    e.preventDefault();
    setActive(!isActive);
  };
  return (
    <div className="dropdown__parent">
      <div className={!isActive ? "dropdown" : "dropdown__active"}>
        <h1>RZB Baseball</h1>
        <img src={RZBLogo} alt="RazorBack Logo" onClick={handleSetActive}></img>
        <h1>Welcome</h1>
      </div>
    </div>
  );
}

export default Dropdown;
