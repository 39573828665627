import React, { useEffect, useState } from "react";
import "./PostMobile.css";
import { Avatar } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";

import { emailToUserName } from "../../assets/functions";

import {
  firebaseDeletePost,
  firebaseStorageDelete,
  firebaseUpdateDoc,
} from "../../assets/firebase";
import { useStateValue } from "../../assets/stateProvider";
import DeleteModal from "../DeleteModal/DeleteModal";

function Post({
  key,
  photoURL,
  message,
  timestamp,
  displayName,
  image,
  uid,
  postId,
}) {
  const [editMessage, setEditMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [deletePost, setDeletePost] = useState(false);

  const [{ userData }, dispatch] = useStateValue();

  const handleDelete = (e) => {
    e.preventDefault()
    setDeletePost(true);
  };

  const handleDeleteCancel = (e) => {
    e.preventDefault()
    setDeletePost(false);
  };

  const handleEditCancel = (e) => {
    e.preventDefault()
    setEdit(false)
  }

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    console.log(postId);
    await firebaseStorageDelete(image);
    await firebaseDeletePost(postId);
    console.log("post and post image deleted");
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    if (!edit) {
      setEdit(true);
    } else if (edit) {
      await firebaseUpdateDoc(postId, editMessage);
      setEdit(false);
      setEditMessage("");
    }
  };

  const handleEditMessage = (e) => {
    e.preventDefault();
    setEditMessage(e.target.value);
  };

  return (
    <div className="post" key={key}>
      <div className="post__header">
        <Avatar src={photoURL} />
        <div className="post__headerInfo">
          <h5>{userData ? emailToUserName(userData[0].uid) : null}</h5>
          <p>{new Date(timestamp?.toDate()).toUTCString()}</p>
        </div>
      </div>

      <div className="post__image">
        <img src={image} alt="user posted image" />
      </div>
      <div className="post__message">
        <p>{message}</p>

        {userData && userData[0].uid === uid && edit ? (
          <>
            <input
              type="text"
              id="post__messageInput"
              placeholder="Edit your message"
              onChange={handleEditMessage}
              value={editMessage}
            />

            <button onClick={handleEditCancel}>Cancel</button>
          </>
        ) : null}
      </div>

      {userData && userData[0].uid === uid ? (
        <div className="post__icons">
          <div className="post__icon" onClick={handleDelete}>
            {" "}
            <FontAwesomeIcon icon={faTrashAlt} />
            <p>Delete</p>
          </div>
          <div className="post__icon" onClick={handleEdit}>
            {" "}
            <FontAwesomeIcon icon={faEdit} />
            <p>{edit ? "Submit" : "Edit"}</p>
          </div>
        </div>
      ) : null}

      {deletePost ? (
        <DeleteModal
          handleDeleteSubmit={handleDeleteSubmit}
          handleDeleteCancel={handleDeleteCancel}
          
        />
      ) : null}
    </div>
  );
}

export default Post;
