// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import firebase from "firebase";
import "firebase/storage";
import firebaseConfig from "../API/api";
import imageCompression from "browser-image-compression";
// initialize the firebase database
const firebaseApp = firebase.initializeApp(firebaseConfig);

// realtime data base in firestore
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebaseApp.storage();

const updatePostsProfilePic = async (userUID, userProfilePic) => {
  db.collection("posts").onSnapshot((snapshot) => {
    snapshot.forEach((doc) => {
      if (doc.data().uid === userUID) {
        db.collection("posts").doc(doc.id).update({ photoURL: userProfilePic });
        console.log(doc.data().photoURL);
        // db.collection('posts').doc().update({photoURL: userProfilePic})
      }
    });
  });
};

const updateDisplayName = (newDisplayName) => {
  let user = auth.currentUser;

  user
    .updateProfile({
      displayName: newDisplayName,
    })
    .then(function () {
      console.log("DISPLAY_NAME UPDATED");
    })
    .catch((error) => {
      console.log(error);
    });
};

const firebaseStorageDelete = async (url) => {
  try {
    const deleteRef = storage.refFromURL(url);
    await deleteRef.delete();
    console.log("File Deleted");
  } catch (error) {
    let errorCode = error.code;
    let errorMessage = error.message;
    console.log("ERROR_CODE/MESSAGE >>>", errorCode, errorMessage);
  }
};

// delete current profile picture
// update with a new picture
const firebaseUserPicUpdate = async (imageFile, imgUrl, userName) => {
  try {
    const storageRefProfilePic = storage.ref(
      `profilePics/${userName}/${imageFile.name}`
    );
    const options = {
      maxSizeMB: 6,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      initialQuality: 0.5,
    };
    const compressedFile = await imageCompression(imageFile, options);

    await storageRefProfilePic.put(compressedFile);

    const URL = await storageRefProfilePic.getDownloadURL();

    await auth.currentUser.updateProfile({
      photoURL: URL,
    });

    if (imgUrl === null) {
      console.log("No file to delete");
    } else {
      let deleteRef = await firebaseStorageDelete(imgUrl);
      if (deleteRef) {
        console.log("Previous Profile Image Deleted");
      }
    }

    return URL;
  } catch (error) {
    let errorCode = error.code;
    let errorMessage = error.message;
    console.log("ERROR_CODE/MESSAGE >>>", errorCode, errorMessage);
  }
};

const firebaseDeletePost = async (postId) => {
  try {
    const deleteRef = db.collection("posts").doc(postId);
    await deleteRef.delete();
    console.log("File Deleted");
  } catch (error) {
    let errorCode = error.code;
    let errorMessage = error.message;
    console.log("ERROR_CODE/MESSAGE >>>", errorCode, errorMessage);
  }
};

const firebaseUpdateDoc = async (postId, postUpdate) => {
  try {
    const updateRef = db.collection("posts").doc(postId);
    await updateRef.update({
      message: postUpdate
    });
    console.log("Message Updated");
  } catch (error) {
    let errorCode = error.code;
    let errorMessage = error.message;
    console.log("ERROR_CODE/MESSAGE >>>", errorCode, errorMessage);
  }
}

// const firebaseGetPosts = async () => {
//   db.collection("posts")
//       .orderBy("timestamp", "desc")
//       .onSnapshot((snapshot) => {
//         dispatch({
//           type: "POST_DATA",
//           post: snapshot.docs.map((doc) => ({
//             postId: doc.id,
//             postData: doc.data(),
//           })),
//         });
//       });
// }

export {
  updatePostsProfilePic,
  updateDisplayName,
  db,
  auth,
  storage,
  firebaseUserPicUpdate,
  firebaseDeletePost,
  firebaseStorageDelete,
  firebaseUpdateDoc,
};
