import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about">
      <div className="about__content">
        <h1>About RZB Baseball </h1>
        <p>
          Razorback Baseball (RZB) is a competitive Club based in Riverton, UT.
          Following our Mission Statement of “Providing a Foundational
          Experience for Baseball and Life,” our Club prepares its players for
          High School and College Baseball. Although we strive to win every game
          we play, we will not sacrifice baseball etiquette or fundamentals. We
          focus very hard on the fundamentals of the game, respect for the game,
          and respect for your team mates, coaches, opponents and most
          importantly parents. We are a family of baseball players and coaches
          that enjoy each other on and off the field. Many of our coaches have
          played in Jr. College, College, or Major League Organizations. Each
          coach brings a specific area of focus, a wealth of baseball knowledge
          and real world experience to our team. Each player and coach are
          expected to review and operate under the RZB Standards, Head Coaches
          Expectations, and Player contract. We are proud of what RZB Baseball
          represents! Tyler Baldwin is the founder of RZB Baseball.
        </p>
      </div>
    </div>
  );
}

export default About;
