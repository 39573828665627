import React, { useState } from "react";
import "./ContactMobile.css";
import emailjs from "emailjs-com";

const Contact = () => {
  const [templateParams, setTemplateParams] = useState({
    fName: "",
    lName: "",

    email: "",
    message: "",
  });

  const serviceID = "service_9k5udyp";
  const userID = "user_5828w7SwtIBtQIaDKUD3b";
  const templateID = "template_nhobf4i";

  function sendEmail(e) {
    e.preventDefault();

    // console.log(emailjs)

    emailjs.send(serviceID, templateID, templateParams, userID).then(
      (result) => {
        console.log(result.text, result.status);
      },
      (error) => {
        console.log(error.text);
      }
    );

    setTemplateParams({
      fName: "",
      lName: "",

      email: "",
      message: "",
    });
  }

  function handleFNameInput(e) {
    e.preventDefault();
    setTemplateParams({ ...templateParams, fName: e.target.value });
    console.log(templateParams.fName);
  }

  function handleLNameInput(e) {
    setTemplateParams({ ...templateParams, lName: e.target.value });
    console.log(templateParams.lName);
  }

  function handleEmailInput(e) {
    e.preventDefault();
    setTemplateParams({ ...templateParams, email: e.target.value });
    console.log(templateParams.email);
  }

  function handleMessageInput(e) {
    e.preventDefault();
    setTemplateParams({ ...templateParams, message: e.target.value });
    console.log(templateParams.message);
  }

  function handleKeyPress(e) {
    if (e.which === 13) {
      e.preventDefault();
    }
  }

  return (
    <div className="contact">
      <h2>Razorback Contact</h2>
      <form id="contact">
        <div className="contact__input">
          <label id="contact__label" htmlFor="firstName">
            <span role="img" aria-label="baseball">
              ⚾
            </span>{" "}
          </label>
          <input
            type="text"
            id="contact"
            name="firstname"
            placeholder="Your first name.."
            onChange={handleFNameInput}
            onKeyPress={handleKeyPress}
            value={templateParams.fName}
          ></input>
        </div>
        <div className="contact__input">
          <label id="contact__label" htmlFor="lastName">
            <span role="img" aria-label="baseball">
              ⚾
            </span>{" "}
          </label>
          <input
            type="text"
            id="contact"
            name="lastname"
            placeholder="Your last name.."
            onChange={handleLNameInput}
            onKeyPress={handleKeyPress}
            value={templateParams.lName}
          ></input>
        </div>
        <div className="contact__input">
          <label id="contact__label" htmlFor="country">
            <span role="img" aria-label="baseball">
              ⚾
            </span>{" "}
          </label>
          <input
            type="text"
            id="contact"
            name="email"
            placeholder="Your email address.."
            onChange={handleEmailInput}
            onKeyPress={handleKeyPress}
            value={templateParams.email}
          ></input>
        </div>
        <div className="contact__input">
          <label id="contact__label" htmlFor="subject">
            <span role="img" aria-label="baseball">
              ⚾
            </span>{" "}
          </label>
          <textarea
            id="subject"
            name="subject"
            placeholder="Write something.."
            onChange={handleMessageInput}
            onKeyPress={handleKeyPress}
            value={templateParams.message}
          ></textarea>
        </div>

        <input
          id="contact_submit"
          type="submit"
          value="Submit"
          className="contact__submit"
          onClick={sendEmail}
        ></input>
      </form>
    </div>
  );
};

export default Contact;
