// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
    apiKey: "AIzaSyBW2SeHlM8OhLCsJoJxuolVdGX0mulGsoU",
    authDomain: "rzbbaseball-e5cd1.firebaseapp.com",
    projectId: "rzbbaseball-e5cd1",
    storageBucket: "rzbbaseball-e5cd1.appspot.com",
    messagingSenderId: "262990790889",
    appId: "1:262990790889:web:4529ed0452f1166eba7078",
    measurementId: "G-920TK7LEC2"
};


export default firebaseConfig;
