import React from 'react'
import './DeleteModalMobile.css'

function DeleteModal({handleDeleteSubmit, handleDeleteCancel}) {
    return (
        <div className="deleteModal">

            
            <p>Do you really want to delete this post?</p>
            <button onClick={handleDeleteSubmit}>Delete</button>
            <button onClick={handleDeleteCancel}>Cancel</button>
            
        </div>
    )
}

export default DeleteModal
